<template xmlns:el-col="http://www.w3.org/1999/html">
  <div>
    <el-container class="app_content">
      <!--      头部信息-->
      <el-header class="he">
        <el-row type="flex">
          <div class="navbar-title" :class="phone===true?'left':''">
            <i v-if="phone" class="fa fa-bars" @click.stop="edu"></i>
            在线教育系统
          </div>

          <el-menu :router="true"
                   v-if="!phone"
                   :default-active="activeIndex"
                   active-text-color="#1989fa"
                   mode="horizontal"
                   class="menu">
            <el-menu-item class="item" index="/">首页</el-menu-item>
            <el-menu-item class="item" :index="item.to" v-for="item in top" :key="item.name">
              {{ item.name }}
            </el-menu-item>
            <el-menu-item class="item" index="/editor">在线oj</el-menu-item>
            <el-submenu class="item" index="/add">

              <template slot="title">创作中心</template>
              <el-menu-item index="/add/editor">博客</el-menu-item>
              <el-menu-item index="/add/course">课程</el-menu-item>
              <!--                <el-menu-item index="/add/My-Vue-Quill-Editor">富文本编辑器</el-menu-item>-->
            </el-submenu>

            <!--            <el-submenu class="item" index="/editor">-->
            <!--              <template slot="title">在线oj</template>-->
            <!--              <el-menu-item index="/editor/my-CodeMirror">CodeMirror编辑器</el-menu-item>-->
            <!--              <el-menu-item index="/editor/my-vue-monaco-editor">微软编辑器monaco</el-menu-item>-->
            <!--              <el-menu-item index="/editor/my-vue-ace">ace编辑器</el-menu-item>-->
            <!--            </el-submenu>-->
            <el-submenu class="item" index="/" v-if="this.$store.state.user.isLogin">
              <template slot="title">
                <el-avatar @click.native="toself" :src="this.$store.state.user.avatar"></el-avatar>
              </template>
              <el-menu-item index="/self">个人中心</el-menu-item>
              <el-menu-item index="/account">账号管理</el-menu-item>
              <el-menu-item @click="out">登出</el-menu-item>
            </el-submenu>
            <el-menu-item class="item" index="/login" v-else>
              <el-button>登录/注册</el-button>
            </el-menu-item>
          </el-menu>
        </el-row>
      </el-header>
      <!--            接下来是主体部分-->
      <el-main class="content_main">
        <router-view></router-view>
        <mfoot></mfoot>
      </el-main>
    </el-container>

    <el-drawer
        title="在线教育"
        :custom-class="drawer"
        :visible.sync="showDrawer"
        :direction="direction">
      <el-menu :router="true"
               :default-active="activeIndex"
               active-text-color="#1989fa"
               class="menu">
        <el-menu-item class="item" index="/">首页</el-menu-item>
        <el-menu-item class="item" :index="item.to" v-for="item in top" :key="item.name">
          {{ item.name }}
        </el-menu-item>
        <el-submenu class="item" index="/add">
          <template slot="title">创作中心</template>
          <el-menu-item index="/add/editor">博客</el-menu-item>
          <el-menu-item index="/add/course">课程</el-menu-item>
        </el-submenu>
        <el-submenu class="item" index="/editor">
          <template slot="title">在线oj</template>
          <el-menu-item index="/editor/my-CodeMirror">CodeMirror编辑器</el-menu-item>
          <el-menu-item index="/editor/my-vue-monaco-editor">微软编辑器monaco</el-menu-item>
          <el-menu-item index="/editor/my-vue-ace">ace编辑器</el-menu-item>
        </el-submenu>
        <el-submenu class="item" index="/" v-if="this.$store.state.user.isLogin">
          <template slot="title">
            <el-avatar @click.native="toself" :src="this.$store.state.user.avatar"></el-avatar>
          </template>
          <el-menu-item index="/self">个人中心</el-menu-item>
          <el-menu-item index="/account">账号管理</el-menu-item>
          <el-menu-item @click="out">登出</el-menu-item>
        </el-submenu>
        <el-menu-item class="item" index="/login" v-else>
          <el-button>登录/注册</el-button>
        </el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
//首页信息

import {getId, getName, getToken} from "@/utils/auth";
import mfoot from "@/components/foot/mfoot";
import {isEmpty, isPhone} from "@/utils/StringUtils";
import store from "@/store";

export default {
  name: "index",
  data() {
    return {
      drawer: 'drawer',
      direction: 'ltr',
      showDrawer: false,
      phone: false,
      isLogin: false,
      activeIndex: '/',
      size: "medium",
      //用户信息
      user: {
        url: require("@/assets/logo.png"),
        // 姓名
        name: "",
        //角色信息
        roles: []
      },
      // 导航条部分
      top: [

        {name: "考研", to: "/study/graduate"},
        // {name: "直播", to: "/live"},
        {name: "课程", to: "/video"},
        {name: "博客", to: "/blog"},
        // {name: "消息", to: "/chat"},
        {name: "题库", to: "/paper"},
        // {name: "编辑器", to: "/editor"},
        // {name: "帮助", to: "/help"},
      ],
    }
  },
  components: {mfoot},

  mounted() {
    this.phone = isPhone();
    let token = getToken();
    if (!isEmpty(token)) {
      this.$store.dispatch("user/getInfo").then(result => {
        // console.log(result)
      }).catch(error => {
        this.$message.error(error.message)
      })
    }
  },
  methods: {
    out() {
      this.$store.dispatch("user/logout").then(result => {
        this.$message({
          type: "success",
          message: result,
          duration: 2 * 1000
        })
      })
    },
    toself() {
      this.$router.push("/self")
    },
    center() {
      this.$router.push("/self")
    },
    edu() {
      if (this.phone) {
        this.showDrawer = !this.showDrawer;
      }
    },
    clock() {

    }
  },


}
</script>

<style scoped>
.el-drawer {
  width: 50% !important;
}

.he {
  /*background-color: #23282d;*/
  padding: 0;
  background-color: #fff;
  /*display: flex;*/
  /*border: 1px solid #e6e6e6;*/
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

.title {
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.navbar-title {
  flex: 1;
  font-size: 25px;
  padding: 0 20px;
  line-height: 60px;
}

.left {
  text-align: left;
}

.menu {
  /*float: right;*/
  /*margin: 0 auto;*/

  /*width: 100%;*/
  /*display: inline-block;*/
}

.content_main {
  padding: 0;
  /*height: 100%;*/
  height: calc(100vh - 60px);
  overflow: auto;
}

.logo-title {
  font-size: 30px;
  font-weight: normal;
  color: #0078ff;
  margin-right: 20%;
  margin-left: 10%;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

.item {
  font-size: 1rem;
}

:root {
  /********主题颜色********/
  --text-gray: #354954;
  --text-light: #686666da;
  --bg-color: #0f0f0f;
  --wihte: #ffff;
  --midnight: #104f55;
  --sky: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
  /****x****主题颜色****x****/
  /********主题字体********/
  --Abel: 'Abel', cursive;
  --Anton: 'Anton', cursive;
  --Josefin: 'Josefin', cursive;
  --Lexend: 'Lexend', cursive;
  --Livvic: 'Livvic', cursive;
  /*****x***主题字体***x*****/
}

/*脚部 css*/
.footer {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color: #181818;
  color: #fff;
}


</style>
