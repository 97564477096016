<template>
  <div class="foot">
    <div>
      有问题请联系203462009@qq.com | 苏ICP备19074673号
    </div>
    <div>
      @2021-至今
    </div>
  </div>
</template>

<script>
export default {
  name: "mfoot"
}
</script>

<style scoped>
.foot{
  margin: 10px auto;
  width: 100%;
  min-height: 40px;
}
</style>